<template>
  <div class="content-wrapper site-content-wrapper clearfix">
    <pre><center>数独用户服务协议</center>

本协议为您与管理者之间所订立的契约，具有合同的法律效力，请您仔细阅读。

1 特别提示

    1.1 为了更好地为您提供服务，请您仔细阅读这份协议。本协议是您与本应用就您安装使用等全部行为所订立的权利义务规范。您在使用时，表明您已完全同意并接受本协议，愿意遵守本协议的各项规则、规范的全部内容，若不同意则可停止使用本应用平台。如您是未成年人，您还应要求您的监护人仔细阅读本协议，并取得他/他们的同意。

    1.2 为提高用户的使用感受和满意度，用户同意本应用将基于用户的操作行为对用户数据进行调查研究和分析，从而进一步优化服务。

2 服务内容

    2.1 数独是一个益智游戏软件，严禁一切非法、涉黄信息，违反规范者一律封号处理。

    2.2 本应用服务的具体内容由本应用制作者根据实际情况提供。

    2.3 除非服务协议另有其它明示规定，本应用所推出的新产品、新功能、新服务，均受到本协议规范。

    2.4 本应用仅提供相关的网络服务，除此之外与相关网络服务有关的设备(如个人电脑、手机、及其他与接入互联网或移动网有关的装置)及所需的费用(如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费)均应由用户自行负担。

3 使用规则

    3.1 用户有义务保证密码和私密空间帐号的安全，用户利用该密码和帐号所进行的一切活动引起的任何损失或损害，由用户自行承担全部责任，本应用不承担任何责任。因黑客行为或用户的保管疏忽导致帐号非法使用，本应用不承担任何责任。

    3.2 用户承诺对其上传于本应用的所有信息(即属于《中华人民共和国著作权法》规定的作品，包括但不限于文字、图片、音乐、电影、表演和录音录像制品和电脑程序等)均享有完整的知识产权，或者已经得到相关权利人的合法授权；如用户违反本条规定造成本应用被第三人索赔的，用户应全额补偿本应用的一切费用(包括但不限于各种赔偿费、诉讼代理费及为此支出的其它合理费用)；

    3.3 当第三方认为用户上传于本应用的信息侵犯其权利，并根据《信息网络传播权保护条例》或者相关法律规定向本应用发送权利通知书时，用户同意本应用可以自行判断决定删除涉嫌侵权信息，除非用户提交书面证据材料排除侵权的可能性，本应用将不会自动恢复上述删除的信息；

        (1)不得为任何非法目的而使用网络服务系统；

        (2)遵守所有与网络服务有关的网络协议、规定和程序；

        (3)不得利用本应用的服务进行任何可能对互联网的正常运转造成不利影响的行为；

        (4)不得利用本应用服务进行任何不利于本应用的行为。

    3.4 如用户在使用网络服务时违反上述任何规定，本应用有权要求用户改正或直接采取一切必要的措施(包括但不限于删除用户上传的内容、暂停或终止用户使用网络服务的权利)以减轻用户不当行为而造成的影响。

4 责任声明

    4.1 任何网站、单位或者个人如认为本应用或者本应用提供的相关内容涉嫌侵犯其合法权益，应及时向本应用提供书面权力通知，并提供身份证明、权属证明及详细侵权情况证明。本应用在收到上述法律文件后，将会尽快切断相关内容以保证相关网站、单位或者个人的合法权益得到保障。

    4.2 用户明确同意其使用本应用网络服务所存在的风险及一切后果将完全由用户本人承担，本应用对此不承担任何责任。

    4.3 本应用无法保证网络服务一定能满足用户的要求，也不保证网络服务的及时性、安全性、准确性。

    4.4 本应用不保证为方便用户而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由本应用实际控制的任何网页上的内容，本应用不承担任何责任。

5 知识产权

    5.1 本应用特有的标识、版面设计、编排方式等版权均属本应用享有，未经本应用许可授权，不得任意复制或转载。

    5.2 本应用所有用户上传内容仅代表用户自己的立场和观点，与本应用无关，由用户本人承担一切法律责任。

    5.3 上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经本应用、用户或相关权利人书面许可，任何人不得以任何形式进行使用。
    </pre>
  </div>
</template>

<script>
export default {
  name: "Terms",
};
</script>
